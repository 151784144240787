import { render, staticRenderFns } from "./VaccinationType.vue?vue&type=template&id=7421e4ce&scoped=true&"
import script from "./VaccinationType.vue?vue&type=script&lang=js&"
export * from "./VaccinationType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7421e4ce",
  null
  
)

export default component.exports